.react-pdf__Document.makeStyles-pdfMainComponent-19 > .react-pdf__Page > .react-pdf__Page__canvas {
    width: 100% !important;
    height: 100% !important;
}

#certificate canvas {
    width: 100% !important;
}

.react-pdf__Page{
    width: 100% !important;
}

.pdf__Page__canvas {
    height: auto !important;
}